import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as MicOffIcon } from "../assets/Mic-off-user.svg";
import { ReactComponent as MicOffIconOutLined } from "../assets/Micoff-bottom.svg";
import { ReactComponent as PinIcon } from "../assets/pin.svg";
import MoreVertTwoToneIcon from "@material-ui/icons/MoreVertTwoTone";

import React, { useRef, useState } from "react";
import AudioLevelIndicator from "../Utils/AudioLevels";
import { toPascalCase } from "../../../lib/helper";
import { LocalizedString, videoSettings } from "../../../Utils/Constants";
import { ReactComponent as UnPinIcon } from "../assets/unpin.svg";
import { sharedObject } from "../../../Utils/common";

const DisplayUserNameWithOption = ({
  user,
  pinnedUserId,
  unPinUser,
  pinUser,
  showOptions,
  muteUser,
  localizedObject,
  // connectionQuality,
  noisyMics,
}) => {
  const moreInfoRef = useRef();
  const [openOption, setOpenOption] = useState(false);

  return (
    <>
      <div
        style={{
          backgroundColor: videoSettings.nameLabel.background,
        }}
        className='cc-name-label'
      >
        {user.isAudioMuted || !user.audioTrack ? (
          <MicOffIcon style={{ color: "#06AA21", height: 24, width: 24 }} />
        ) : (
          <AudioLevelIndicator
            noisy={user && noisyMics ? noisyMics[user.id] === true : false}
            track={user.audioTrack}
          />
        )}

        <Typography
          variant='h5'
          style={{
            color: "#fff",
            marginLeft: 4,
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "20vw",
            height: "20px",
          }}
        >
          {toPascalCase(user.name || "")}
        </Typography>

        {showOptions ? (
          <>
            <IconButton
              style={{
                border: "none",
                outline: "none",
                padding: 0,
              }}
              component='span'
              ref={moreInfoRef}
              onClick={(e) => {
                e.preventDefault();
                setOpenOption(true);
              }}
              edge='end'
              aria-controls='simple-menu'
            >
              <MoreVertTwoToneIcon style={{ color: "#fff" }} />
            </IconButton>
            <Popper
              open={openOption}
              role={undefined}
              anchorEl={moreInfoRef.current}
              transition
              disablePortal
              placement={"top-end"}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "top",
                  }}
                >
                  <Paper style={{ backgroundColor: "rgb(30, 30, 30)" }}>
                    <ClickAwayListener onClickAway={() => setOpenOption(false)}>
                      <MenuList
                        id='menu-list-grow'
                        onKeyDown={() => {
                          setOpenOption(null);
                        }}
                      >
                        {user.audioTrack &&
                        !user.isAudioMuted &&
                        !user.isLocalUser &&
                        sharedObject.isPresenter !== false ? (
                          <MenuItem
                            onClick={() => {
                              setOpenOption(null);

                              muteUser(user);
                            }}
                          >
                            <div style={{ marginRight: 15 }}>
                              <MicOffIconOutLined
                                style={{ color: "#fff", height: 24, width: 24 }}
                                fontSize='medium'
                              />
                            </div>
                            <Typography style={{ color: "#fff" }} variant='h5'>
                              {localizedObject["MUTE_USER"]}
                            </Typography>
                          </MenuItem>
                        ) : null}
                        {/*  <MenuItem
                        onClick={() => {
                          setOpenOption(null);
                        }}
                      >
                        <div style={{ marginRight: 15 }}>
                          <RemoveCircleOutlineIcon fontSize="medium" />
                        </div>
                        <Typography variant="inherit">Kick</Typography>
                      </MenuItem> */}
                        {pinnedUserId === user.id ? (
                          <MenuItem
                            onClick={() => {
                              setOpenOption(null);
                              unPinUser();
                            }}
                          >
                            <div style={{ marginRight: 15 }}>
                              <UnPinIcon
                                style={{ color: "#fff", height: 24, width: 24 }}
                                fontSize='medium'
                              />
                            </div>
                            <Typography style={{ color: "#fff" }} variant='h5'>
                              {localizedObject["UNPIN_USER"]}
                            </Typography>
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => {
                              pinUser(user);
                              setOpenOption(null);
                            }}
                          >
                            <div style={{ marginRight: 15 }}>
                              <PinIcon
                                style={{ color: "#fff", height: 24, width: 24 }}
                                fontSize='medium'
                              />
                            </div>
                            <Typography style={{ color: "#fff" }} variant='h5'>
                              {localizedObject["PIN_USER"]}
                            </Typography>
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(DisplayUserNameWithOption);

DisplayUserNameWithOption.defaultProps = {
  user: null,
  pinnedUserId: "",
  unPinUser: () => {},
  pinUser: () => {},
  showOptions: false,
  muteUser: () => {},
  localizedObject: LocalizedString,
};

DisplayUserNameWithOption.propTypes = {
  user: PropTypes.object,
  pinnedUserId: PropTypes.string,
  unPinUser: PropTypes.func,
  pinUser: PropTypes.func,
  showOptions: PropTypes.bool,
  muteUser: PropTypes.func,
  localizedObject: PropTypes.object,
};
